import React from "react"
import elementClass from "element-class"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import containerStyles from "./index.module.css"

class IndexPage extends React.Component {
  componentDidMount() {
    function playText(text, domNode, interval) {
      interval = interval || 205
      const wordsWithTimers = text.split(" ")
      const expr = /~\d+(.\d+)?/

      let result = []
      for (let i = wordsWithTimers.length - 1; i >= 0; i--) {
        let match = wordsWithTimers[i].match(expr)
        let delayFactor = parseFloat(match ? match[0].replace(/~/, "") : 1)
        const value = match
          ? wordsWithTimers[i].replace(match[0], "")
          : wordsWithTimers[i]
        result.push({
          value,
          delayFactor,
        })
      }

      result.reverse()

      function setText(text) {
        domNode.textContent = text
      }

      let totalSecondsForRollingText = result.reduce(
        (previousValue, currentValue, index, array) => {
          const obj = array[index]
          setTimeout(() => {
            setText(obj.value)
          }, previousValue)
          return previousValue + obj.delayFactor * interval
        },
        0
      )

      setTimeout(() => {
        let node = document.createElement("a")
        node.href = "mailto:eirik@insj.as"
        node.textContent = "eirik@insj.as"
        domNode.appendChild(node)
        setTimeout(() => {
          elementClass(document.querySelector(".image")).add("image--isVisible")
        }, 400)

        setTimeout(() => {
          elementClass(document.querySelector(".links")).add("links--isVisible")
        }, 500)
      }, totalSecondsForRollingText)
    }

    function play(delay, start) {
      const text =
        (start || "Heisann!") +
        "~4 ~2 Jeg heter Eirik.~3 ~2 Jeg liker å lage apps og webtjenester.~4 ~3.5"

      setTimeout(() => {
        playText(text, document.querySelector(".enjoying-itself"))
      }, delay)
    }
    play(1000)
  }

  render() {
    return (
      <Layout>
        <SEO title="Eirik Stien" keywords={[`eirik stien`]} />
        <div className="content">
          <h1 className={containerStyles.heading}>Eirik Stien</h1>
          <Image />
          <div className="enjoying-itself" />
          <div className="links">
            <OutboundLink href="https://no.linkedin.com/in/eirikstien">
              LinkedIn
            </OutboundLink>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
